(function ($) {
    var INDEX_STEP = 'index',
        INITIAL_STEP_INDEX = 0,
        INDEX_REVEALED_CLASS = 'opened';
    $.fn.wizard = function (options) {
        var defaultOpts = {
            selectors: {
                content:".wiz-content",
                navPanel:".wiz-nav-panel",
                nav:".wiz-nav",
                prev:".wiz-nav .prev a",
                idx:".wiz-nav .index a",
                next:".wiz-nav .next a"
            },
            initial_step: INITIAL_STEP_INDEX
        };

        function Wizard(el, opts) {
            this.$el = $(el);
            this.opts = $.extend(defaultOpts, opts);
            this.init();
        }
        $.extend(Wizard.prototype, {
            /**
             * Initializes wizard widget
             */
            init: function () {
                //grabs elements for the future use, stores them in $[SELECTOR] e.g. this.$content, this.$nav etc
                var selectors = this.opts.selectors;
                for (var sel in selectors) {
                    if (!selectors.hasOwnProperty(sel)) {
                        continue;
                    }
                    this["$"+sel] = this.$el.find(this.opts.selectors[sel]);
                }

                this.steps = {_ids:[]};
                var self = this;
                // find and store "steps"
                this.$content.find("section").each(function() {
                    var $section = $(this),
                        step = {
                            title: $section.find(".step-title").text(),
                            id: $section.data('stepId'),
                            isIndex: $section.data('stepType') === INDEX_STEP,
                            el: $section
                        };
                    self.steps[step.id] = step;
                    self.steps._ids.push(step.id);
                    if (step.isIndex) {
                        self.steps._index = step;
                    }
                });
                this.currentStep = INITIAL_STEP_INDEX;
                this.bindEvents();
                console.log("STEPID:"+this.opts.initial_step)
                this.linkTo(this.opts.initial_step);
            },
            bindEvents: function() {
                var self = this;
                this.$el.click(function(event) {
                    var target = event.target,
                        $el;
                    // since we handle *every* click in the .wizard, we have to check if that's a link or any element
                    // within a link
                    while (target !== this && target.tagName !== "A") {
                        target = target.parentNode;
                    }
                    $el = $(target);
                    if ($el.data('role') !== 'steplink') {
                        return true;
                    }
                    event.preventDefault();
                    var stepIdLink = $el.attr('href'),
                        stepId = '';
                    if (stepIdLink.length > 0) {
                        stepId = stepIdLink.substr(1);
                        if (self.steps[stepId] === undefined) {
                            console.error("Linking to the wrong step");
                        }
                    }
                    else {
                        console.error("Linking to the wrong step");
                    }
                    self.linkTo(stepId);
                    return false;
                });
                //process click on the index link
                this.$idx.click(function(event) {
                    event.preventDefault();
                    self.$navPanel.toggleClass(INDEX_REVEALED_CLASS);
                    return false;
                });
            },
            linkTo: function(stepId) {
                stepId = stepId || this.steps._index.id;
                var steps = this.steps,
                    ids = this.steps._ids,
                    oldStepIdx = this.currentStep,
                    oldStep = steps[ids[oldStepIdx]],
                    newStepIdx = ids.indexOf(stepId),
                    newStep = steps[stepId];
                this.currentStep = newStepIdx;
                this.updateNav();
                this.changeStep(oldStep, newStep);
            },
            /**
             * Changes step from an old to the new
             * @param {Object} oldStep Previous step that has to be hidden
             * @param {Object} newStep New step that has to be shown
             */
            changeStep: function (oldStep, newStep) {
                if (oldStep.id !== newStep.id) {
                    oldStep.el.hide();
                }
                newStep.el.show();
                this.$el.scrollTop(0);
                if (this.$navPanel.hasClass(INDEX_REVEALED_CLASS)) {
                    this.$navPanel.removeClass(INDEX_REVEALED_CLASS);
                }
            },
            /**
             * Updates navigation bar
             */
            updateNav: function () {
                var ids = this.steps._ids,
                    prevIdx = this.currentStep === 0 ? ids.length - 1 : this.currentStep - 1,
                    nextIdx = this.currentStep === ids.length - 1 ? 0 : this.currentStep + 1;
                this.$prev.attr("href", "#"+ids[prevIdx]).text(this.steps[ids[prevIdx]].title);
                this.$next.attr("href", "#"+ids[nextIdx]).text(this.steps[ids[nextIdx]].title);
            }

        });

        return this.each(function () {
            if (!$.data(this, "wiz")) {
                $.data(this, "wiz", new Wizard(this, options));
            }
        });
    };
})(jQuery);