/*!
 *  Stunnplate script.js
 *  Version: 1.3.2
 *
 */
 /* 
 *  
 *  Notes:
 *  Main JS file to include entire library followed by custom scripts.
 *
 * 
 */

/*-------------------------------------------------------*/
/*- SITE SPECIFIC SCRIPT                                -*/
/*-------------------------------------------------------*/

//This is no longer the place for page specific stuff. make sure you use _pagespecific.js for that! (040117 MG)


/********************************************************************/

$(function() {

    var main_mnu = $(".main_mnu");
    $(document).on("click", ".menu_btn", function () {
    
        $(".main_mnu_list li").removeClass("visible");
    
        var item = $(".main_mnu_list li"),
            duration = 200,
            i = 1;
        main_mnu.toggleClass("open");
        $(".bg-mpf").addClass("active");
        setTimeout(function () {
    
            var timerId = setTimeout(function go() {
                $(".main_mnu_list li:nth-child(" + i + ")").addClass("visible");
                if (i < item.length) {
                    setTimeout(go, duration);
                } else {
                    $(".main_mnu_close").addClass("visible");
                }
                i++;
            }, duration);
    
        }, 400);
    
    });
    
    $(document).on("click", ".main_mnu_close", function () {
    
        $(".main_mnu_list li").removeClass("visible");
        $(".main_mnu_close").removeClass("visible");
        main_mnu.removeClass("open");
    
    });

    /*$(".timeline_year_marker").on("click", function() {

        var _this = $(this),
            container = _this.closest(".timeline_year"),
            item = container.find(".timeline_year_item"),
            desc = container.find(".timeline_year_item_desc"),
            img = container.find(".timeline_year_item_img");

        if ( container.hasClass("active") ) {
            desc.slideUp();
            img.slideUp();
            container.removeClass("active");
            item.removeClass("active");
        } else {
            desc.slideDown();
            img.slideDown();
            container.addClass("active");
            item.addClass("active");
        }

    });

    $(".timeline_year_item_title").on("click", function() {

        var _this = $(this),
            container = _this.closest(".timeline_year"),
            item = _this.closest(".timeline_year_item"),
            desc = item.find(".timeline_year_item_desc"),
            img = item.find(".timeline_year_item_img");

        if (item.hasClass("active")) {
            desc.slideUp();
            img.slideUp();
            item.removeClass("active");
        } else {
            desc.slideDown();
            img.slideDown();
            item.addClass("active");
        }

        var flag = false;
        container.find(".timeline_year_item").each(function() {
            if ($(this).hasClass("active")) {
                flag = true; 
            }
        });

        if ( flag === true ) {
            container.addClass("active");
        } else {
            container.removeClass("active");
        }

    });*/

    $(".ripple").on("click", function(event) {
		var _this = $(this),
			offset = $(this).offset(),
			positionX = (event.pageX - offset.left),
			positionY = (event.pageY - offset.top);
		_this.append("<span class='ripple-effect'>");
		_this.find(".ripple-effect").css({
		   left: positionX,
		   top: positionY
		}).animate({
			opacity: 0,
		  }, 1500, function() {
		   $(this).remove();
		});
	});

	$(".ripple-hover").hover(function() {
		var _this = $(this),
			offset = $(this).offset(),
			positionX = (event.pageX - offset.left),
			positionY = (event.pageY - offset.top);
		_this.append("<span class='ripple-hover-effect'>");
		_this.find(".ripple-hover-effect").css({
		   left: positionX,
		   top: positionY
		}).animate({
			opacity: 0,
		  }, 1500, function() {
		   $(this).remove();
		});
    }, function() {});
    


    formingHrefTel();

});


function isNumber(n) {
	return !isNaN(parseFloat(n)) && isFinite(n);
}
function formingHrefTel() {

	var linkAll = $('.formingHrefTel'),
		joinNumbToStringTel = 'tel:';

	$.each(linkAll, function () {
		var _this = $(this),
			linkValue = _this.text(),
			arrayString = linkValue.split("");

		for (var i = 0; i < arrayString.length; i++) {
			var thisNunb = isNumber(arrayString[i]);
			if (thisNunb === true || (arrayString[i] === "+" && i === 0)) {
				joinNumbToStringTel += arrayString[i];
			}
		}

		_this.attr("href", function () {
			return joinNumbToStringTel;
		});
		joinNumbToStringTel = 'tel:'

	});

}

/* ---------------------------------------------------------------------- */
/*	Google Translate
/* ---------------------------------------------------------------------- */

function googleTranslateElementInit() {
	new google.translate.TranslateElement({
		pageLanguage: 'en',
		includedLanguages: 'be,bg,bs,cs,cy,da,de,el,en,es,et,fi,fr,ga,hr,hu,is,it,lt,lv,mk,nl,no,pl,pt,ro,sk,sl,sq,sr,sv,tr,uk',
		layout: google.translate.TranslateElement.InlineLayout.SIMPLE,
		autoDisplay: false}, 'google_translate_element');

}