/*!
 *  Stunnplate modal.js
 *  Version: 2.0.1
 */
/*
 *  Last Updated: 21/10/16
 *  By: Mark
 *
 *  Notes:
 *
 *
 */

// New modal layout
//
(function ($) {

    $.fn.modal = function (options) {
        var settings = $.extend({
            modalTemplate: '<div id="modal-ajax" class="modal-wrap">'
            +   '<div class="modal-overlay">'
            +       '<div class="loader"></div>'
            +       '<div class="modal-close"><svg class="icon-cross"><use xlink:href="#icon-modalClose"></use></svg></div>'
            +       '<div class="modal-contentWrap">'
            +           '<div class="modal-content"></div>'
            +           '<div class="modal-footer"></div>'
            +       '</div>'
            +   '</div>'
            +'</div>',
            loadTarget: null,
            loadMethod: "ajax", //Either onPage or ajax
            thisModal : $("#modal-ajax"),
            modalContent : ".modal-content",
            type : null,
            class : null,
            onclick : true,
            onclickFn : null,
            customCallback : null
        }, options);

        return this.each(function () {

            var $this = $(this),
                loadTarget = $this.attr('href') || settings.loadTarget,
                loadMethod = $this.data("loadMethod") || settings.loadMethod, //Either onPage or ajax
                type  = $this.data("modaltype") || settings.type,
                modalClass = $this.data("modalclass") || settings.class,
                trigger = $this,
                $thisModal = $this.data("thismodal") || settings.thisModal,
                onclick = $this.data("onclick") || settings.onclick,
                onclickFn = $this.data("onclickFn") || settings.onclickFn,
                modalContent = $this.data("modalcontent") || settings.modalContent;


            console.log(settings.modalTemplate);

            var setTarget = function(newTarget) {

                if(newTarget.indexOf("#") == 0){ // This is for when the modal content is on page, may need to add backwards compatability for when whole modal is on page
                    loadTarget = newTarget;
                    loadMethod = "onPage";
                }
                else{
                    var ext = newTarget.split('.').pop().toLowerCase();

                    //Target is image
                    if($.inArray(ext, ['gif','png','jpg','jpeg']) > 1) {
                        loadMethod = "image";
                        loadTarget = newTarget;
                    } else{

                        // Target is selecting part of another page
                        if(newTarget.indexOf("#") > 0){

                            var idIndex = newTarget.indexOf("#"),
                                paramIndex = newTarget.indexOf('?'),
                                targetID = newTarget.substring(idIndex, paramIndex != -1 ? paramIndex : newTarget.length);

                            if(paramIndex > idIndex){
                                loadTarget = newTarget.replace(targetID,"") + " " + targetID;
                            } else{
                                loadTarget = newTarget.replace(/#/," #");
                            }

                        } else{
                            // Target is selecting whole of another page, so just grab the section tags, we don't want head etc.
                            loadTarget = newTarget + " section";
                        }
                    }
                }
            };
            var open = function(){ // Args: ?,callback

                    window.activeModalCtrl = $this;

                    if(loadMethod === "ajax"){ // @TODO I don't think the modal-ajax bit should be here
	                    // Check if modal code exists on page
	                    if($("body #modal-ajax").length == 0){
	                        // If it doen't then write modal dialog to page
	                        $thisModal = $(settings.modalTemplate);
	                        $("body").append($thisModal);
	                        console.log($thisModal);
	                    } else{
	                        $thisModal = $("body #modal-ajax");
	                    }
                    	
                    }
                    // Add class to page and set body overflow to hidden
                    $('body').addClass('modal-open');
                    $("body").css("overflow","hidden");

                    // Add class to modal if necessary @TODO I don't think the modal-ajax bit should be here
                    if(loadMethod === "ajax"){
                    	$("#modal-ajax").addClass("active");
                    }
                    $thisModal.addClass(modalClass);
                    // Add close listeners
                    $('.modal-close').on('click touchend',function () {
                        close();
                    });

                    $('.modal-overlay').on('click touchend',function (e) {
                        var target = e.target;

                        if($(target).hasClass("modal-overlay")){
                            close();
                        }
                    });

                    // Bind keys
                    bindKeydown();

                    // Type based open methods
                    if (typeof methodsOpen[type] == "function") {
                        methodsOpen[type]($this);
                    }else{
                        //console.warn("Cannot find function "+functionName);
                    }

                    // Trigger modal open event
                    $this.trigger("opened");

                    // Load content
                    loadContent();

                },
                close = function(){

                    //window.activeModalCtrl // - Maybe reset this?

                    $(".modal-wrap.active").removeClass("active loaded");
                    $(document).unbind('keydown');

                    // Remove class from body and set overflow to visible
                    $("body").css("overflow","visible");
                    $('body').removeClass('modal-open');

                    // Remove class from modal
                    $thisModal.removeClass(modalClass);
                    // $(".modal-contentWrap.is-login").removeClass("is-login");

                    //Unbind keys
                    unbindKeydown();

                    // Type based open methods
                    if (typeof methodsClose[type] == "function") {
                        methodsClose[type]($this);
                    } else{
                        //console.warn("Cannot find function "+functionName);
                    }

                    // Trigger modal open event
                    $this.trigger("closed");

                },
                bindKeydown = function(clickOnRtn){
                    //bind key events so we can close the dialogue
                    $(document).bind('keydown', function(e){
                        // e.preventDefault();
                        var key = e.which || e.keyCode || e.charCode;
                        switch (key){
                            case 27: // [esc]
                                $(".modal-wrap.active").removeClass("active loaded");
                                $(document).unbind('keydown');
                                break;
                        }
                    });

                },
                unbindKeydown = function(){
                    //unbind key events
                    $(document).unbind('keydown'); // Probably not what we want - make modal specific
                },
                loadContent = function(){
                    if (typeof methodsLoad[loadMethod] == "function") {
                        methodsLoad[loadMethod]($this);
                    }else{
                        // console.warn("There is no such load method: " + loadMethod );
                    }
                },
                setOnClickFn = function(fn) {
                    onClickFn = fn;
                },
                methodsLoad = {
                    ajax : function(){
                         
                        $("#modal-ajax").find(modalContent).load(loadTarget, function(responseText, statusText, xhr) {
                            if(statusText == "error"){

                                // Type based error methods
                                if (typeof methodsError[type] == "function") {
                                    methodsError[type]($this);
                                }else{
                                    $("#modal-ajax").find(modalContent).html("<h1>Sorry, an error has occured: " + xhr.status + " - " + xhr.statusText);
                                }
                                console.log("ERROR")
                            }

                            if(statusText == "success") {
                                modalLoaded();
                                
                                // Type based success methods
                                if (typeof methodsSuccess[type] == "function") {
                                    methodsSuccess[type]($this);
                                }else{
                                    //console.warn("Cannot find function "+functionName);
                                }

                                $this.trigger("loaded");
                            }
                        });
                    },
                    onPage : function(){
                        // @TODO Need to implemenet this method
						$(loadTarget).addClass("active");

                        modalLoaded();
                        // Type based success methods
                        if (typeof methodsSuccess[type] == "function") {
                            methodsSuccess[type]($this);
                        }else{
                            //console.warn("Cannot find function "+functionName);
                        }
                        $this.trigger("loaded");
                    },
                    image : function(){
                        // @TODO Need to test this method
                        $("#modal-ajax").find(modalContent).html("<img src='" + loadTarget + "' />");
                        modalLoaded();
                        // Type based success methods
                        if (typeof methodsSuccess[type] == "function") {
                            methodsSuccess[type]($this);
                        }else{
                            //console.warn("Cannot find function "+functionName);
                        }
                        $this.trigger("loaded");
                    }
                },
                modalLoaded = function(){
                    $("#modal-ajax").addClass("loaded");
                },
                methodsOpen = {

                },
                methodsClose = {

                },
                methodsSuccess = {
                    login : function(){
                        $(modalContent + " .tabbed" ).tabme();
                        // $(".modal-content select" ).select2();

                        if($this.data("defaulttab") === "login") {
                            $('[href="#tabLogin"]').trigger("click");
                        }
                    },
                    carousel : function(){ //@TODO Needs testing!
                        var clickedElIndex = 0;
                        if($this.prop("tagName").toLowerCase() === "li"){
                            clickedElIndex = $this.index(0);
                        }

                        $($thisModal + modalContent).on('transitionend',function(){
                            $($thisModal).find(".carousel:first").trigger('goTo',clickedElIndex);
                            $($thisModal + modalContent).off('transitionend');
                        });
                    },
                    accordion : function(){
                        $(modalContent + " .accordion" ).accordion();
                    }
                },
                methodsError = {

                };

                // Check for custom on click behabiour, otherwise go ahead with default
                if(onclick) {

                    // Add listeners for modal links
                    $this.on('click touchend',function(e){
                        e.preventDefault();

                        if (typeof onclickFn === "function") {
                            onclickFn.call();
                        } else{
                            // Ensure that the load target is correctly formatted and the correct load method has been set
                            setTarget(loadTarget);

                            // One we have a target, open the modal
                            open();
                        }

                    });

                }

                setTarget(loadTarget);

                if( typeof settings.customCallback == "function") {
                    $this.on('callCustom',settings.customCallback());
                }

                return {
                    open: open,
                    setTarget : setTarget,
                    setOnClickFn : setOnClickFn
                }
        });
        //requires jQuery so we dont initialise until dom ready
    };

}(jQuery));

$(function(){
    $(".modal-link").modal();
    // 
    //
    // 
     // var closure = function(){
     //    thismodal.open();
     // }; 
    $(".modal-link_mp").modal({modalTemplate: '<div id="modal-ajax" class="modal-wrap">'
            +   '<div class="modal-overlay">'
            +       '<div class="loader"></div>'
            +       '<div class="modal-close"><svg class="icon-cross"><use xlink:href="#icon-modalClose"></use></svg></div>'
            +       '<div class="modal-contentWrap">'
            +           '<div id="mp-wrapper" class="mp-wrapper modal-demo"></div>'
            +           '<div class="modal-footer"></div>'
            +       '</div>'
            +   '</div>'
            +'</div>'});
});


/* Example Usage:
 elem.modal({
 loadTarget: "<THE URL YOU WANT>",
 type : "login",
 class : "is-login",
 onclick : false,
 customCallback : <CALLBACK FUNCTION>
 });
 */