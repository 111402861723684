/*--------------------------------------------------
         IE 10 and IE 11 detection
---------------------------------------------------*/
// var isIE11 = !!navigator.userAgent.match(/Trident.*rv\:11\./) || !!navigator.userAgent.match(/Trident.*rv\:10\./);
// alert(isIE11);
function sniffbrowser() {

	var ie10 = /MSIE\s([\d]+)/;
	var ua = navigator.userAgent;
	var matched = ua.match(ie10);
	
	if (matched) {
		// Success! Here's where we do something unique if it is IE 10
		$( "html" ).addClass( "ie10" );
	}

	var ie11 = /Trident.*rv\:11\./;
	var ua = navigator.userAgent;
	var matched2 = ua.match(ie11);
	
	if (matched2) {
		// Success! Here's where we do something unique if it is IE 11
		$( "html" ).addClass( "ie11" );
	}
}