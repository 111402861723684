/*
	Define page specific scripts here which will be loaded by _domreadyutil.js based on the ID and class of the body tag 
	of any page
 */

// The utility converts dashed classnames to camelCase as we can't use dashes in our variables here so resourcesPage = resources-page

PAGESCRIPT = {
  	common : {
    	init     : function(){
			sniffbrowser();

			// This is where you can put GLOBAL dom ready scripts


            /* search/filter via ajax */
            $(function(){
                var debouncer;
                $('body')
                    .on('change','.projects_filter',function(e){
                        clearTimeout(debouncer);
                        debouncer = setTimeout(function(){
                            $('.projects_filter').trigger("submit");
                        }, 600);
                    })
                    .on('submit','.projects_filter',function(e){
                        e.preventDefault();
                        var url     = $(this).attr('action'),
                            data    = $(this).serialize();

                        $('body').addClass("ajaxLoading");
                        $('#filtered-results').load(url + data + " #filtered-results > *", function(responseText, statusText, xhr){
                            $('body').removeClass("ajaxLoading").addClass('ajaxLoaded');
                            var resultsCount = parseInt($('.search-result-count').first().text()) || 0;
                            $(".search-results").text(resultsCount + " result"+((resultsCount!=1)?"s":""));
                        });
                    });
            });


            //.projects_filter show/hide
            $(function(){
                var $projects_filter = $(".projects_filter").hide(),
                    $projects_filter_toggle = $("<a href='#'>Show Filters</a>");
                $projects_filter.before($projects_filter_toggle);
                $projects_filter_toggle.on("click",function(e){
                    e.preventDefault();
                    if($projects_filter.is(":visible")){
                        $projects_filter.hide(400);
                        $projects_filter_toggle.text("Show Filters");
                    } else {
                        $projects_filter.show(400);
                        $projects_filter_toggle.text("Hide Filters");
                    }
                })
            })
			
    	},
    	finalize : function() {

    	}
  	},
  	contactPage : {
    	init     : function() {
			// This is an example of where to put PAGE SPECIFIC dom ready scripts. The script automaticall converts to camelCase for example, .contact-page becomes contactPage here
		    
    	}
  	}
};